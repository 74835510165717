import { API } from "aws-amplify";
import { GetAllOrganizationAssets } from "@/mixins/GetAllOrganizationAssets.js";
export const GetCurrentUser = {
  mixins: [GetAllOrganizationAssets],
  data() {
    return {
      GetCurrentUserList: [],
    };
  },
  methods: {
    async GetCurrentUserMethod() {
      var self = this;
      self.overlay = true;
      const path = "getcurrentuserdetails";
      const myInit = {
        body: {
          user_email_id: this.$store.getters.get_user_email,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          self.overlay = false;
          if (!response.errorType) {
            self.GetCurrentUserList = response.data.items;
            self.$store.commit("SET_CURRENTUSER_DETAILS", self.GetCurrentUserList[0]);
            self.GetAllAssetsAcrossOrganizationMethod(self.GetCurrentUserList[0].organization_id);
          } else {
            self.GetCurrentUserList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
};
