import { API } from "aws-amplify";
export const GetAllOrganizationAssets = {
  data() {
    return {
      GetAllAssetsAcrossOrganizationList: {},
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetAllAssetsAcrossOrganizationMethod(organization_id) {
      var self = this;
      self.overlay = true
      const path = "listassestsacrossorganization";
      const myInit = {
        body: {
          organization_id: organization_id,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          self.overlay = false
          if (!response.errorType) {
            self.GetAllAssetsAcrossOrganizationList = response.data.items.sort((a, b) => a.asset_status > b.asset_status);
          } else {
            self.GetAllAssetsAcrossOrganizationList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
};
