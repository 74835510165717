import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";
import LandingPage from "../views/MainHomePages/LandingPage.vue";
import AssetsSelectionsPage from "../views/MainHomePages/AssetsSelectionsPage.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: LandingPage,
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import("@/views/ChildHomePages/LandingPages/Dashboard.vue"),
      },
      {
        path: "/Template2",
        name: "Template2",
        component: () => import("@/views/ChildHomePages/LandingPages/Template2.vue"),
      },
      {
        path: "/Template3",
        name: "Template3",
        component: () => import("@/views/ChildHomePages/LandingPages/Template3.vue"),
      },
      {
        path: "/KPIs",
        name: "KPIs",
        component: () => import("@/views/ChildHomePages/LandingPages/KPIs.vue"),
      },
      {
        path: "/Trends",
        name: "Trends",
        component: () => import("@/views/ChildHomePages/LandingPages/Reports/Trends.vue"),
      },
      {
        path: "/ProductionLogs",
        name: "ProductionLogs",
        component: () => import("@/views/ChildHomePages/LandingPages/Reports/ProductionLogs.vue"),
      },
      {
        path: "/ConnectivityReports",
        name: "ConnectivityReports",
        component: () => import("@/views/ChildHomePages/LandingPages/Reports/ConnectivityReports.vue"),
      },
      {
        path: "/MyAlarms",
        name: "MyAlarms",
        component: () => import("@/views/ChildHomePages/LandingPages/MyAlarms.vue"),
      },
      {
        path: "/AlarmLogs",
        name: "AlarmLogs",
        component: () => import("@/views/ChildHomePages/LandingPages/AlarmLogs.vue"),
      },
      {
        path: "/Analysis",
        name: "Analysis",
        component: () => import("@/views/ChildHomePages/LandingPages/Alarms/Analysis.vue"),
      },
      {
        path: "/DailyLogs",
        name: "DailyLogs",
        component: () => import("@/views/ChildHomePages/LandingPages/DailyLogs.vue"),
      },
      {
        path: "/OperationalParameter",
        name: "OperationalParameter",
        component: () => import("@/views/ChildHomePages/LandingPages/Settings/OperationalParameter.vue"),
      },
      {
        path: "/AlarmConfiguration",
        name: "AlarmConfiguration",
        component: () => import("@/views/ChildHomePages/LandingPages/Settings/AlarmConfiguration.vue"),
      },
      {
        path: "/HeartbeatSettings",
        name: "HeartbeatSettings",
        component: () => import("@/views/ChildHomePages/LandingPages/Settings/HeartbeatSettings.vue"),
      },
      {
        path: "/UserManagement",
        name: "UserManagement",
        component: () => import("@/views/ChildHomePages/LandingPages/Settings/UserManagement.vue"),
      },
      {
        path: "/Departments",
        name: "Departments",
        component: () =>
          import(
            /* webpackChunkName: "Administrations" */ "@/views/ChildHomePages/LandingPages/Settings/Departments.vue"
          ),
      },
      {
        path: "/Roles",
        name: "Roles",
        component: () =>
          import(/* webpackChunkName: "Administrations" */ "@/views/ChildHomePages/LandingPages/Settings/Roles.vue"),
      },
      {
        path: "/AuditLogs",
        name: "AuditLogs",
        component: () =>
          import(/* webpackChunkName: "Administrations" */ "@/views/ChildHomePages/LandingPages/AuditLogs.vue"),
      },
    ],
  },
  {
    path: "/AssetsSelectionsPage",
    name: "AssetsSelectionsPage",
    component: AssetsSelectionsPage,
    children: [
      // {
      //   path: "/AssetsSelectionsPage",
      //   name: "AssetsSelectionsPage",
      //   component: () => import("@/views/ChildHomePages/AssetsSelectionsPage.vue"),
      // },
    ],
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: () => import("@/views/ChildHomePages/ReportIssue.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
