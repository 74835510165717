import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Amplify from "aws-amplify";
import "@/plugins/apexcharts";
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_oiDiz12kL",
    region: "ap-south-1",
    userPoolWebClientId: "6u68gegvmc0752edgv5pgnr8l1",
  },
  API: {
    endpoints: [
      {
        name: "CHAINPORTAL",
        endpoint: "https://chc5fvje6c.execute-api.ap-south-1.amazonaws.com/chainhome/",
      },
      {
        name: "Report_issue",
        endpoint: " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
      },
    ],
  },
});
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
