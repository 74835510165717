<template>
  <v-app>
    <Overlay :overlay="overlay" />
    <CreateIssueDialog
      :dialogCreateIssue="dialogCreateIssue"
      :selectedFilesFromDrop="selectedFilesFromDrop"
      @clicked="dialogCreateIssueEmit"
    />
    <v-app-bar app clipped-left color="white" elevation="1">
      <v-app-bar-nav-icon
        color="background"
        v-if="$vuetify.breakpoint.name == 'xs'"
        @click="navDrawer = !navDrawer"
      ></v-app-bar-nav-icon>
      <v-card class="elevation-0 pr-4">
        <v-img contain width="70px" src="@/assets/ChainHome.png"></v-img>
      </v-card>
      <span
        v-for="(item, idx) in user_type != 'NORMAL_USER'
          ? RouterList
          : RouterList.filter((route) => route.MenuName != 'Settings' && route.MenuName != 'Audit Logs')"
        :key="idx"
      >
        <v-btn
          small
          v-if="item.has_child_routes == false"
          class="text-capitalize"
          text
          :color="item.MenuRoute == $route.name ? 'primary' : ''"
          @click="$route.name != item.MenuName ? $router.push(item.MenuRoute) : ''"
          >{{ item.MenuName }}</v-btn
        >
        <v-menu offset-y v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="text-capitalize" v-bind="attrs" v-on="on" text prepend-icon="mdi-chevron-down"
              >{{ item.MenuName }}<v-icon x-small>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(sitem, index) in item.SubMenu"
              :key="index"
              @click="$route.name != sitem.MenuName ? $router.push(sitem.MenuRoute) : ''"
            >
              <v-list-item-title>{{ sitem.MenuName }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
      <v-spacer></v-spacer>
      <span>
        <div class="fontSize">
          {{ $store.getters.get_user_email }}
        </div>
      </span>
      <!-- <v-badge content="0" class="mx-2" color="red" overlap bordered><v-icon color="primary">mdi-bell</v-icon></v-badge> -->
      <v-icon class="mx-2" :color="heart_beat_color.toLowerCase()" dark>mdi-factory</v-icon>
      <v-icon class="mx-2" :color="connected == true ? 'green' : 'red'" dark>mdi-monitor</v-icon>
      <!-- to="/AssetsSelectionsPage" -->
      <v-btn icon @click="CallLogInLogOutAPIListMethod('SIGN_OUT')" :loading="overlay">
        <v-icon size="50px" color="primary" dark>mdi-menu-left</v-icon>
      </v-btn>
      <v-tooltip bottom>
        <!--dialogCreateIssue = true  -->
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="htmlToImageMethod()"
            color="primary"
            :disabled="overlay == true ? true : false"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Facing Issues Using ChainHome ? Click To Report</span>
      </v-tooltip>
      <v-btn icon to="/" @click="closeHeartBeatInterval()">
        <v-icon color="primary" large dark>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <div ref="home">
        <v-card
          class="elevation-0"
          @drop.prevent="onDrop($event)"
          @dragover.prevent="onDragOver($event)"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="onDragLeave($event)"
        >
          <router-view v-if="dragover == false" @connected="storeConnectedStatus" @heartBeatColor="getHeartBeatColor" />
          <v-card class="elevation-0" v-else :height="Height">
            <v-card fill-height fluid :height="`${Height - 120}`" class="elevation-0 borderDotted ma-8">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <v-icon size="60px" color="primary">mdi-paperclip-plus</v-icon>
                    <h2 class="mt-2">Please Drag And Drop An Attachment To Report And Issue</h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-card>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { CallLogInLogOutAPI } from "@/mixins/CallLogInLogOutAPI.js";
import RouterList from "@/JsonFiles/RouterList.json";
import Overlay from "@/components/Extras/Overlay.vue";
import CreateIssueDialog from "@/components/Extras/CreateIssueDialog.vue";
import * as htmlToImage from "html-to-image";
export default {
  components: {
    Overlay,
    CreateIssueDialog,
  },
  mixins: [CallLogInLogOutAPI],
  data: () => ({
    IssueReportDialog: false,
    dialogCreateIssue: false,
    dragover: false,
    Height: 0,
    selectedFilesFromDrop: [],
    user_type: "",
    heart_beat_color: "",
    connected: false,
    overlay: false,
    RouterList: [],
    selectedItem: 0,
    cleartimeout: null,
    storeinterval: null,
    count: 0,
  }),
  watch: {
    "$route.name"(value) {
      console.log("check the value", value);
      this.overlay = false;
    },
  },
  mounted() {
    this.RouterList = RouterList;
    this.user_type = this.$store.getters.get_current_user_details.user_type;
    this.CallLogInLogOutAPIListMethod("SIGN_IN");
    this.updateNavigationValueMethod();
  },
  methods: {
    htmlToImageMethod() {
      this.overlay = true;
      this.$forceUpdate();
      this.selectedFilesFromDrop = [];
      var self = this;
      setTimeout(() => {
        htmlToImage.toBlob(this.$refs.home).then(function (blob) {
          blob.name = `ScreenShot${new Date().getTime()}.png`;
          self.selectedFilesFromDrop.push(blob);
          let object = {
            selectedFilesFromDrop: self.selectedFilesFromDrop,
            routeName: self.$route.name,
          };
          self.$store.commit("SET_ISSUE_DETAILS", object);
          self.$store.commit("SET_PREVIOUS_ROUTE", self.$route.name);
          self.$router.push("ReportIssue");
        });
        // self.overlay = false;
      }, 2000);
    },
    updateNavigationValueMethod() {
      console.log("route name", this.$route.name);
      let previous_route = this.$store.getters.get_previous_route;
      if (previous_route && previous_route != "") {
        switch (previous_route) {
          case "Dashboard":
            this.selectedItem = 0;
            break;
          case "KPIs":
            this.selectedItem = 1;
            break;
          case "AlarmLogs":
            this.selectedItem = 2;
            break;
          case "MyAlarms":
            this.selectedItem = 3;
            break;
          case "Analysis":
            this.selectedItem = 4;
            break;
          case "Trends":
            this.selectedItem = 5;
            break;
          case "ProductionLogs":
            this.selectedItem = 6;
            break;
          case "ConnectivityReports":
            this.selectedItem = 7;
            break;
          case "DailyLogs":
            this.selectedItem = 8;
            break;
          case "OperationalParameter":
            this.selectedItem = 9;
            break;
          case "AlarmConfiguration":
            this.selectedItem = 10;
            break;
          case "UserManagement":
            this.selectedItem = 11;
            break;
          case "Roles":
            this.selectedItem = 12;
            break;
          case "Departments":
            this.selectedItem = 13;
            break;
          case "AuditLogs":
            this.selectedItem = 14;
            break;
        }
      }

      this.$forceUpdate();
    },
    getHeartBeatColor(heart_beat_color) {
      this.heart_beat_color = heart_beat_color;
    },
    storeConnectedStatus(connected) {
      this.connected = connected;
    },

    onDragLeave() {
      this.dragover = false;
      this.selectedFilesFromDrop = [];
      this.$forceUpdate();
    },
    onDragOver() {
      this.dragover = true;
      this.selectedFilesFromDrop = [];
      this.$forceUpdate();
    },
    onDrop(event) {
      console.log("Check The Event", event.dataTransfer.files);
      this.selectedFilesFromDrop = event.dataTransfer.files;
      this.dialogCreateIssue = true;
      this.$forceUpdate();
    },
    dialogCreateIssueEmit(Toggle) {
      this.loading = false;
      this.dialogCreateIssue = false;
      this.selectedFiles = [];
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
