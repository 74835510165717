<template>
  <v-card class="elevation-0" height="100%" color="shades">
    <Overlay :overlay="overlay" />
    <v-app-bar app clipped-left color="white" elevation="1">
      <v-app-bar-nav-icon
        color="background"
        v-if="$vuetify.breakpoint.name == 'xs'"
        @click="navDrawer = !navDrawer"
      ></v-app-bar-nav-icon>
      <v-card class="elevation-0">
        <v-img contain width="70px" src="@/assets/ChainHome.png"></v-img>
      </v-card>
      <v-spacer></v-spacer>
      <span>
        <div class="PrimaryFontColorSmall">
          {{ $store.getters.get_user_email }}
        </div>
      </span>
      <v-btn icon to="/">
        <v-icon color="primary" large dark>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-card-text>
        <v-row no-gutters justify="center" align="center">
          <v-col
            v-for="(item, idx) in GetAllAssetsAcrossOrganizationList"
            :key="idx"
            cols="12"
            xs="12"
            sm="12"
            md="3"
            @click="pushToLandingPage(item)"
          >
            <v-card outlined height="100px" class="ma-3" style="cursor: pointer">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="3" xs="12">
                    <v-avatar color="primary" size="60">
                      <span class="white--text">{{ item.asset_type }}</span>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" sm="12" md="8" xs="12">
                    <h2>{{ item.asset_name }}</h2>
                    <h3
                      class="PrimaryFontMediumNormalColor"
                      :class="item.asset_status == 'ACTIVE' ? 'green--text' : 'red--text'"
                    >
                      {{ item.asset_status }}
                    </h3>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-main>
  </v-card>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllDashboardParameter } from "@/mixins/GetAllDashboardParameter.js";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
export default {
  mixins: [GetCurrentUser, GetAllDashboardParameter],
  components: {
    Overlay,
  },
  data: () => ({
    overlay: false,
  }),
  mounted() {
    this.GetCurrentUserMethod();
    this.$store.commit("SET_ASSET_OBJECT", {});
  },
  methods: {
    pushToLandingPage(item) {
      this.$store.commit("SET_ASSET_OBJECT", item);
      this.overlay = true;
      var self = this;
      setTimeout(() => {
        if (
          self.$store.getters.get_asset_object.template_id == 1 ||
          self.$store.getters.get_asset_object.template_id == undefined
        ) {
          self.$router.push("/Dashboard");
        } else if (self.$store.getters.get_asset_object.template_id == 2) {
          self.$router.push("/Template2");
        } else if (self.$store.getters.get_asset_object.template_id == 3) {
          self.$router.push("/Template3");
        }

        self.overlay = false;
      }, 1000);
    },
  },
};
</script>
