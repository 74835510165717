var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('CreateIssueDialog',{attrs:{"dialogCreateIssue":_vm.dialogCreateIssue,"selectedFilesFromDrop":_vm.selectedFilesFromDrop},on:{"clicked":_vm.dialogCreateIssueEmit}}),_c('v-app-bar',{attrs:{"app":"","clipped-left":"","color":"white","elevation":"1"}},[(_vm.$vuetify.breakpoint.name == 'xs')?_c('v-app-bar-nav-icon',{attrs:{"color":"background"},on:{"click":function($event){_vm.navDrawer = !_vm.navDrawer}}}):_vm._e(),_c('v-card',{staticClass:"elevation-0 pr-4"},[_c('v-img',{attrs:{"contain":"","width":"70px","src":require("@/assets/ChainHome.png")}})],1),_vm._l((_vm.user_type != 'NORMAL_USER'
        ? _vm.RouterList
        : _vm.RouterList.filter(function (route) { return route.MenuName != 'Settings' && route.MenuName != 'Audit Logs'; })),function(item,idx){return _c('span',{key:idx},[(item.has_child_routes == false)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"small":"","text":"","color":item.MenuRoute == _vm.$route.name ? 'primary' : ''},on:{"click":function($event){_vm.$route.name != item.MenuName ? _vm.$router.push(item.MenuRoute) : ''}}},[_vm._v(_vm._s(item.MenuName))]):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"small":"","text":"","prepend-icon":"mdi-chevron-down"}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.MenuName)),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.SubMenu),function(sitem,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.$route.name != sitem.MenuName ? _vm.$router.push(sitem.MenuRoute) : ''}}},[_c('v-list-item-title',[_vm._v(_vm._s(sitem.MenuName))])],1)}),1)],1)],1)}),_c('v-spacer'),_c('span',[_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(_vm.$store.getters.get_user_email)+" ")])]),_c('v-icon',{staticClass:"mx-2",attrs:{"color":_vm.heart_beat_color.toLowerCase(),"dark":""}},[_vm._v("mdi-factory")]),_c('v-icon',{staticClass:"mx-2",attrs:{"color":_vm.connected == true ? 'green' : 'red',"dark":""}},[_vm._v("mdi-monitor")]),_c('v-btn',{attrs:{"icon":"","loading":_vm.overlay},on:{"click":function($event){return _vm.CallLogInLogOutAPIListMethod('SIGN_OUT')}}},[_c('v-icon',{attrs:{"size":"50px","color":"primary","dark":""}},[_vm._v("mdi-menu-left")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":_vm.overlay == true ? true : false},on:{"click":function($event){return _vm.htmlToImageMethod()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Facing Issues Using ChainHome ? Click To Report")])]),_c('v-btn',{attrs:{"icon":"","to":"/"},on:{"click":function($event){return _vm.closeHeartBeatInterval()}}},[_c('v-icon',{attrs:{"color":"primary","large":"","dark":""}},[_vm._v("mdi-exit-to-app")])],1)],2),_c('v-main',[_c('div',{ref:"home"},[_c('v-card',{staticClass:"elevation-0",on:{"drop":function($event){$event.preventDefault();return _vm.onDrop($event)},"dragover":function($event){$event.preventDefault();return _vm.onDragOver($event)},"dragenter":function($event){$event.preventDefault();_vm.dragover = true},"dragleave":function($event){$event.preventDefault();return _vm.onDragLeave($event)}}},[(_vm.dragover == false)?_c('router-view',{on:{"connected":_vm.storeConnectedStatus,"heartBeatColor":_vm.getHeartBeatColor}}):_c('v-card',{staticClass:"elevation-0",attrs:{"height":_vm.Height}},[_c('v-card',{staticClass:"elevation-0 borderDotted ma-8",attrs:{"fill-height":"","fluid":"","height":("" + (_vm.Height - 120))}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"60px","color":"primary"}},[_vm._v("mdi-paperclip-plus")]),_c('h2',{staticClass:"mt-2"},[_vm._v("Please Drag And Drop An Attachment To Report And Issue")])],1)],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }