import { API } from "aws-amplify";
export const GetAllDashboardParameter = {
  data() {
    return {
      GetAllDashboardParameterList: {},
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetAllDashboardParameterMethod(asset_id) {
      var self = this;
      if (self.$route.name != "Dashboard") {
        self.overlay = true;
        }
      const path = "listtagsoforganization";
      const myInit = {
        body: {
          organization_id: this.$store.getters.get_current_user_details.organization_id,
          asset_id: asset_id,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.GetAllDashboardParameterList = response.data.items;
            console.log('self.GetAllDashboardParameterList',self.GetAllDashboardParameterList)
            self.GetAllDashboardParameterList.map((item) => {
              if(item.type == 'DAILY_LOGS'){
                console.log('Recover Tag',item)
              }
            })
            if (self.$route.name == "OperationalParameter") {
              self.overlay = false;
            }
          } else {
            self.GetAllDashboardParameterList = [];
            self.noDataText = response.errorMessage;
            self.overlay = false;
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
};
