import { API } from "aws-amplify";
export const CallLogInLogOutAPI = {
  data() {
    return {
      CallLogInLogOutAPIList: [],
    };
  },
  methods: {
    async CallLogInLogOutAPIListMethod(action) {
      var self = this;
      self.overlay = true;
      const path = "removealarmconfiguration";
      const myInit = {
        body: {
          command: "signInSignOutLogs",
          organization_id: this.$store.getters.get_asset_object.organization_id,
          asset_id: this.$store.getters.get_asset_object.asset_id,
          user_email_id: this.$store.getters.get_user_email,
          action,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then(() => {
          if (action == "SIGN_OUT") {
            self.$router.push("/AssetsSelectionsPage");
          }
          self.overlay = false;
        })
        .catch((error) => {
          self.overlay = false;
          console.warn(error);
        });
    },
  },
};
